let appName = 'BookTok';

var u = navigator.userAgent;
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
if (isiOS) {
    appName = 'BooksTok'
} else if (isAndroid) {
    appName = 'BookTok'
}

const renewData = {
    'cn': [
        {
            'title': '自動續訂協議',
            'content': []
        },
        {
            'title': '',
            'content': [
                `1、服務名稱：${appName} 1個月連續會員、${appName} 1年連續會員，${appName}1個月會員，開通會員包月、包年會員和1個月會員後，您將獲得的服務期限為731個日曆日、3166個日曆日和31個日曆日。`,
            ]
        },
        {
            'title': '',
            'content': [
                `2、賬戶自動購買的會員訂閱將在月度訂閱到期前24小時從您的Goolge賬戶中扣除，扣除成功後額外增加一個會員周期。`,
            ]   
        },
        {
            'title': '',
            'content': [
                `3、如果要取消訂閱。打開“Goolge Play Store”——選擇要暫停的訂閱——點擊“管理”——點擊“暫停訂閱”—— 取消訂閱。如未在計費期屆滿前至少24小時取消自動續費功能，將自動續費並扣除。`,
            ]
        }
    ],
    'yn': [
        {
            'title': 'Thỏa thuận tự động gia hạn',
            'content': []
        },
        {
            'title': '',
            'content': [
                `1. Tên dịch vụ: Hội viên ${appName} 1 tháng liên tục, Hội viên ${appName} 1 năm liên tục, Hội viên ${appName} 1 tháng, sau khi mở thẻ hội viên hàng tháng, hội viên hàng năm và hội viên 1 tháng, thời hạn dịch vụ bạn sẽ nhận được là 731 ngày dương lịch, 3166 Lịch ngày và 31 dương lịch.`,
            ]
        },
        {
            'title': '',
            'content': [
                `2. Đăng ký thành viên do tài khoản mua tự động sẽ được trừ vào tài khoản Goolge của bạn 24 giờ trước khi đăng ký hàng tháng hết hạn và thời gian thành viên bổ sung sẽ được cộng thêm sau khi khấu trừ thành công.`,
            ]
        },
        {
            'title': '',
            'content': [
                `3. Nếu bạn muốn hủy đăng ký. Mở "Goolge Play Store" -chọn đăng ký bạn muốn tạm ngừng nhấp vào "quản lý" - nhấp vào "tạm ngừng đăng ký" - hủy đăng ký. Nếu chức năng tự động gia hạn không bị hủy ít nhất 24 giờ trước khi hết thời hạn thanh toán, gia hạn sẽ tự động bị trừ.`,
            ]
        }
    ],
    'en': [
        {
            'title': 'Auto-renew agreement',
            'content': []
        },
        {
            'title': '',
            'content': [
                `1. Service name: ${appName} 1-month continuous membership, ${appName} 1-year continuous membership, ${appName} 1-month membership, after opening monthly membership, annual membership and 1-month membership, the service period you will get is 731 calendar days, 3166 Calendar days and 31 calendar days.`,
            ]
        },
        {
            'title': '',
            'content': [
                `2. The membership subscription purchased automatically by the account will be deducted from your Goolge account 24 hours before the monthly subscription expires, and an additional membership period will be added after the deduction is successful.`,
            ]
        },
        {
            'title': '',
            'content': [
                ` 3. If you want to cancel the subscription. Open "Goolge Play Store"-select the subscription you want to suspend-click "manage"-click "suspend subscription"--cancel subscription. If the automatic renewal function is not cancelled at least 24 hours before the expiration of the billing period, the renewal will be automatically deducted.`
            ]
        }
    ],
};

export { renewData };